import { SheetzError, SheetzErrorButtonType } from "classes/SheetzError";
import classNames from "classnames";
import React, { ReactElement, useState } from "react";

import "./CustomizationOption.scss";

import {
  AccompanyingItemUpsell,
  Condiment,
  Option,
  RetailModifiedItem,
  Selector,
  SelectorType,
} from "assets/dtos/anywhere-dto";

import PortioningSelector from "components/Order/ItemCustomization/Selectors/PortioningSelector/PortioningSelector";
import ActionSheet, { ActionSheetColor } from "components/misc/view/ActionSheet/ActionSheet";

import {
  ItemSwitchOption,
  PortionTypes,
  PortionedCondiment,
  SizeSelectOption,
} from "util/Customization.util";
import { IconType, getIcon } from "util/Icon.util";
import { getImageSrc, getImageSrcSet } from "util/Image.util";

interface CustomizationOptionProps {
  // Used across multiple options
  type: SelectorType | "PORTIONING" | "AIU";
  selector?: Selector;
  option?: Option;
  isSelected?: boolean;
  isNoOption?: boolean;
  isGreenBorder?: boolean;
  onNoOptionSelected?: () => void;

  // Size select
  sizeSelectOption?: SizeSelectOption;
  onSizeOptionSelected?: (sizeSelectorOption: SizeSelectOption) => void;

  // Item Switch
  itemSwitchOption?: ItemSwitchOption;
  onSwitchOptionSelected?: (switchRMI?: RetailModifiedItem) => void;
  onItemSwitchOptionSelected?: (itemSwitchOption: ItemSwitchOption) => void;

  // Multi-option Switch
  price?: number;

  // Condiment
  rmi?: RetailModifiedItem;
  condiment?: Condiment;
  portionedCondiment?: PortionedCondiment;
  onCondimentOptionSelected?: (condiment: Condiment, portion?: PortionTypes) => void;

  // Portions
  portion?: PortionTypes;
  onPortionOptionSelected?: (portion: PortionTypes) => void;
  icon?: IconType;

  // AIU
  aiu?: AccompanyingItemUpsell;
  onAIUOptionSelected?: (aiu: AccompanyingItemUpsell) => void;
}

const portionLabelMap = { less: "Lite", regular: "Regular", more: "Heavy", side: "Side" };

const CustomizationOption = (props: CustomizationOptionProps): ReactElement => {
  let associatedRmi: RetailModifiedItem | undefined;
  const associatedCondiment = props.condiment || props.portionedCondiment?.condiment;
  const associatedAIU = props.aiu;
  const [showPortioning, setShowPortioning] = useState(false);

  if (props.type === "SIZE") {
    if (!props.sizeSelectOption) {
      throw new SheetzError("SizeSelectOption is undefined when trying to render size option.", {
        userReadableMessage: "That size isn't available. Please select new size.",
        primaryButton: SheetzErrorButtonType.CHOOSE_NEW_SIZE,
      });
    }
    associatedRmi = props.sizeSelectOption.retailModifiedItem;
  } else if (props.type === "ITEM_SWITCH") {
    if (!props.itemSwitchOption) {
      throw new SheetzError(
        "ItemSwitchOption is undefined when trying to render item switch option.",
        {
          userReadableMessage: "Looks like we've hit a snag. Please try again.",
          primaryButton: SheetzErrorButtonType.TRY_AGAIN,
          secondaybutton: SheetzErrorButtonType.CLOSE,
        }
      );
    }
    associatedRmi = props.itemSwitchOption.switchRmi;
  } else if (props.rmi) {
    associatedRmi = props.rmi;
  } else if (props.type === "AIU") {
    if (!props.aiu) {
      throw new SheetzError("AIU is undefined when trying to render an AIU option.", {
        userReadableMessage: "Unable to add your item. Please try again.",
        primaryButton: SheetzErrorButtonType.TRY_AGAIN,
      });
    }
  } else if (!associatedCondiment && !props.isNoOption) {
    throw new SheetzError("Condiment is undefined when trying to render a condiment option.", {
      userReadableMessage: "There was an error with your selected condiment. Please try again.",
      primaryButton: SheetzErrorButtonType.TRY_AGAIN,
    });
  }

  const buttonClasses = classNames("customization-option", {
    selected: props.isSelected,
    "green-border": props.isGreenBorder,
    condiment: props.type !== "SIZE",
  });

  const isSelectedAndPortioningAvailable =
    !!props.isSelected && !!associatedCondiment && !!associatedCondiment.portions;

  function optionSelected(): void {
    if (props.type === "SIZE" && props.sizeSelectOption) {
      props.onSizeOptionSelected?.(props.sizeSelectOption);
    } else if (associatedCondiment) {
      props.onCondimentOptionSelected?.(associatedCondiment);
    } else if (props.isNoOption) {
      props.onNoOptionSelected?.();
    } else if (props.type === "PORTIONING" && props.portion) {
      props.onPortionOptionSelected?.(props.portion);
    } else if (props.type === "NO_OPTION_SWITCH" && associatedRmi) {
      props.onSwitchOptionSelected?.(associatedRmi);
    } else if (props.type === "ITEM_SWITCH" && props.itemSwitchOption) {
      props.onItemSwitchOptionSelected?.(props.itemSwitchOption);
    } else if (props.type === "AIU" && associatedAIU) {
      props.onAIUOptionSelected?.(associatedAIU);
    }
  }

  function portioningButtonPressed(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
    event.stopPropagation();
    setShowPortioning(true);
  }

  function portionSelected(portion: PortionTypes): void {
    if (associatedCondiment) {
      props.onCondimentOptionSelected?.(associatedCondiment, portion);
    }
    setShowPortioning(false);
  }

  // Priority of option image source: 1. Option 2. Condiment 3. Selector 4. AIU 5. RMI
  const image: string | undefined =
    props.option?.image ||
    props.sizeSelectOption?.option.image ||
    props.itemSwitchOption?.option.image ||
    associatedCondiment?.image ||
    props.selector?.image ||
    associatedAIU?.image ||
    associatedRmi?.image;

  const displayText: string =
    props.option?.text ||
    props.sizeSelectOption?.option.text ||
    props.itemSwitchOption?.option.text ||
    associatedCondiment?.retailModifiedItem.receiptText ||
    associatedRmi?.receiptText ||
    associatedAIU?.retailModifiedItem.receiptText ||
    "";

  const descriptionText: string = associatedCondiment?.retailModifiedItem.descriptiveText || "";

  const calories =
    associatedRmi?.nutrition?.calories ||
    associatedCondiment?.retailModifiedItem?.nutrition?.calories ||
    associatedAIU?.retailModifiedItem?.nutrition?.calories;

  let price =
    props.price?.toFixed(2) ||
    associatedRmi?.price?.toFixed(2) ||
    associatedCondiment?.retailModifiedItem.price.toFixed(2) ||
    associatedAIU?.retailModifiedItem.price.toFixed(2) ||
    null;
  price = Number(price) !== 0 ? price : null;

  return (
    <>
      <button className={buttonClasses} onClick={optionSelected} aria-label={displayText}>
        {props.isSelected && getIcon(IconType.customizationCheck, "customization-check-icon")}
        <div className="flex-container">
          {props.type !== "PORTIONING" && (
            <img
              src={getImageSrc(image)}
              srcSet={getImageSrcSet(image)}
              sizes="125px"
              alt="Option Preview"
            />
          )}
          {props.type === "PORTIONING" && props.icon && getIcon(props.icon, "portioning-icon")}
          <p className="option-name">{displayText}</p>
          {descriptionText && <p className="option-descriptive-text">{descriptionText}</p>}
          {!isSelectedAndPortioningAvailable && (
            <div className="details">
              {!!price && <span className="price">${price}</span>}
              {calories !== undefined && (
                <span className={"nutrition" + (!!price || associatedRmi ? " right" : "")}>
                  {calories} Cals
                </span>
              )}
            </div>
          )}
          {isSelectedAndPortioningAvailable && (
            <div
              className="portioning-button"
              role="button"
              aria-roledescription="Allows for selecttion and deselection of portioning."
              tabIndex={0}
              onClick={(event): void => portioningButtonPressed(event)}
            >
              {props.portionedCondiment?.selectedPortion
                ? portionLabelMap[props.portionedCondiment.selectedPortion]
                : "Portion"}
            </div>
          )}
        </div>
      </button>
      <ActionSheet
        color={ActionSheetColor.red}
        title="Portion Size"
        overlay={true}
        shouldDisplay={showPortioning}
        onOverlayClick={(): void => setShowPortioning(false)}
        cancelFunction={(): void => setShowPortioning(false)}
      >
        {associatedCondiment && (
          <PortioningSelector
            portions={associatedCondiment.portions}
            onPortionSelected={portionSelected}
          />
        )}
      </ActionSheet>
    </>
  );
};

export default CustomizationOption;
